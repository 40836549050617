<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-22 09:22:51
 * @LastEditors: Lizy
 * @LastEditTime: 2021-12-09 13:58:18
-->
<template>
  <div class="customer-case-detail">
    <!-- 客户案列详情版心 -->
    <!-- <div class="product">
      <div class="product_img" v-for="(item, index) in banner" :key="index">
        <div class="banner_img">
            <img
              :src="$utils.picturePrefix(item.image)"
              alt=""
            />
        </div>
        
      </div>
    </div> -->
    <div class="container">
      <!-- 客户案例详情页 -->
      <!-- <div class="sub"></div> -->
      <div class="detail_left">
        <div class="title">
          {{ list.title }}
        </div>
        <!-- <div class="text">{{ list.introduction }}</div>
        <img
          style="margin:40px auto 37px;"
          :src="$utils.picturePrefix(list.image)"
          alt=""
        /> -->
        <div class="details" v-html="list.detail"></div>
      </div>

      <!-- <div class="extra"></div> -->
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      // 客户案列详情页中间展示数据
      list: {},
      banner: []
    };
  },
  computed: {},
  watch: {},
  methods: {
    query_getlist() {
      this.$api.getCustomerCaseDetail(this.$route.query.id).then(res => {
        let { code, data } = res;
        if (code == 200) {
          this.list = data;
        }
      });
    },
    query_banner() {
      this.$api.getBanner({ type: 3 }).then(res => {
        let { code, data } = res;
        if (code == 200) {
          this.banner = data;
          console.log("====",this.banner);
        }
      });
    },
  },
  created() {
    this.query_getlist();
    // this.query_banner();
  }
};
</script>
<style lang="less">
@import "./index.less";
</style>
